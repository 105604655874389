import React, {useState, useContext} from "react";
import { Link } from "react-router-dom";
import "./Footer.css"
import { ReactComponent as WalletLogo } from "../../assets/icons/footer_logo.svg"
import { ReactComponent as AppStoreLogo } from "../../assets/icons/apple.svg"
import { ReactComponent as GoogleStoreLogo } from "../../assets/icons/google.svg"
import { ReactComponent as InstagramLogo } from "../../assets/icons/instagram.svg"
import { ReactComponent as TwitterLogo } from "../../assets/icons/twitter_logo.svg"
import { ReactComponent as TelegramLogo } from "../../assets/icons/telegram.svg"
import {ReactComponent  as LinkedinIcon} from "../../assets/icons/linkedin.svg"
import Button from "../button/Button";
import { AppContext } from "../../context/AppContext"


const Footer = () => {
    const [showAppList, setShowAppList] = useState(false)
    const [showCompanyList, setCompanyList] = useState(false)
    const [showLegalList, setShowLegalList] = useState(false)

    let context = useContext(AppContext);
    let isMobile = context.isMobile;
    let appleLink = context.appleLink;
    let googleLink = context.googleLink;

    const redirectAppStoreLink = () =>{
        window.open(appleLink, "_blank");
    }

    const redirectPlayStoreLink = () =>{
        window.open(googleLink, "_blank");
    }

    return (
        <footer className="footer-container">
            <div className="center-container footer">
                <div className="row m-0 mb-4">
                    <div className="col-6 swifty-wallet-mobile">
                        <WalletLogo />
                    </div>
                    <div className="col-6 d-flex justify-content-end app-store-mobile">
                        <Button
                            className="secondary btn-install-app btn-app-store"
                            ariaLabel="app-store"
                            onClick={redirectAppStoreLink}
                            
                        >
                            <div>
                                <AppStoreLogo />
                            </div>
                        </Button>
                        <Button
                            className="secondary btn-install-app btn-play-store"
                            ariaLabel="google-store"
                            onClick={redirectPlayStoreLink}
                        >
                            <div>
                                <GoogleStoreLogo />
                            </div>
                        </Button>
                    </div>
                </div>
                <div className="row m-0 reverse-mobile">
                    <div className="col-12 col-lg-4 col-xl-4 swifty-info-icons-mobile">
                        <p className="swifty-wallet-info">
                            Swifty Wallet is registered in Ireland with registered address 77 Camden Street, Lower Dublin, D02 XE80 and company number 690741
                        </p>
                        <div className="footer-share-icons">
                            <Link to={{ pathname: "https://www.linkedin.com/company/swifty-global/" }} target="_blank" aria-label="linkedin" rel="noopener noreferrer">
                                <LinkedinIcon/>
                            </Link>
                            <Link to={{ pathname: "https://www.instagram.com/swiftyglobal" }} target="_blank" aria-label="instagram" rel="noopener noreferrer">
                                <InstagramLogo />
                            </Link>
                            <Link to={{ pathname: "https://twitter.com/SwiftyGlobal" }} target="_blank" aria-label="twitter" rel="noopener noreferrer">
                                <TwitterLogo />
                            </Link>
                            <Link to={{ pathname: "https://t.me/swiftyglobal" }} target="_blank" aria-label="telegram" rel="noopener noreferrer">
                                <TelegramLogo />
                            </Link>
                        </div>
                    </div>
                    <div className="col-12 col-lg-8 d-flex justify-content-end footer-app-company-legal-mobile">
                        <div className="footer-app">
                            <span className="footer-label">App</span>
                            {
                                isMobile ? (
                                    <img 
                                        className="polygon_logo_app" 
                                        src="/assets/images/footer_polygon.svg" 
                                        onClick={(e) => setShowAppList(!showAppList)}
                                        alt="footer polygon"
                                    />
                                ) : ''
                            }
                            {
                                !isMobile ? (
                                    <div className="footer-items ">
                                        <Link className="footer-nav-link" aria-label="download" to={"/download"}>Download</Link>
                                        <Link className="footer-nav-link" aria-label="features" to={"/features"}>Features</Link>
                                        <Link className="footer-nav-link" aria-label="academy" to={"/crypto"}>Academy</Link>
                                    </div>
                                ) : ""
                            }
                        </div>
                        {
                            showAppList ? (
                                <div className="footer-items ">
                                    <Link className="footer-nav-link" aria-label="download" to={"/download"}>Download</Link>
                                    <Link className="footer-nav-link" aria-label="features" to={"/features"}>Features</Link>
                                    <Link className="footer-nav-link" aria-label="academy" to={"/crypto"}>Academy</Link>
                                </div>
                            ) : ""
                        }
                        <div className="footer-company">
                            <span className="footer-label">Company</span>
                            {
                                isMobile ? (
                                    <img 
                                        className="polygon_logo_company" 
                                        src="/assets/images/footer_polygon.svg" 
                                        onClick={(e) => setCompanyList(!showCompanyList)}
                                        alt="footer polygon company"
                                    />
                                ) : ''
                            }
                            {
                                !isMobile ? (
                                    <div className="footer-items ">
                                        <Link className="footer-nav-link" aria-label="about-us" to={"/about_us"}>About Us</Link>
                                        <Link className="footer-nav-link" aria-label="contact-us" to={"/contact_us"}>Contact Us</Link>
                                        <Link className="footer-nav-link" aria-label="faq" to={"/faq"}>FAQ</Link>
                                    </div>
                                ) : ""
                            }
                        </div>
                        {
                            showCompanyList ? (
                                <div className="footer-items ">
                                    <Link className="footer-nav-link" aria-label="about-us" to={"/about_us"}>About Us</Link>
                                    <Link className="footer-nav-link" aria-label="contact-us" to={"/contact_us"}>Contact Us</Link>
                                    <Link className="footer-nav-link" aria-label="faq" to={"/faq"}>FAQ</Link>
                                </div>
                            ) : ""
                        }
                        <div className="footer-legal">
                            <span className="footer-label">Legal</span>
                            {
                                isMobile ? (
                                    <img 
                                        className="polygon_logo_legal" 
                                        src="/assets/images/footer_polygon.svg" 
                                        onClick={(e) => setShowLegalList(!showLegalList)}
                                        alt="footer polygon legal"
                                    />
                                ) : ''
                            }
                            {
                                !isMobile ? (
                                    <div className="footer-items ">
                                        <Link className="footer-nav-link" aria-label="terms-and-conditions " to={"/terms_and_conditions"}>Terms & Conditions</Link>
                                        <Link className="footer-nav-link" aria-label="privacy-policy" to={"/privacy_policy"}>Privacy Policy</Link>
                                        <Link className="footer-nav-link" arial-label="cookie-policy" to={"/cookie_policy"}>Cookie Policy</Link>
                                    </div>
                                ) : ""
                            }
                        </div>
                        {
                            showLegalList ? (
                                <div className="footer-items ">
                                    <Link className="footer-nav-link" aria-label="term-and-conditions" to={"/terms_and_conditions"}>Terms & Conditions</Link>
                                    <Link className="footer-nav-link" aria-label="pricacy-policy" to={"/privacy_policy"}>Privacy Policy</Link>
                                    <Link className="footer-nav-link" aria-label="cookie-policy" to={"/cookie_policy"}>Cookie Policy</Link>
                                </div>
                            ) : ""
                        }
                    </div>
                </div>
                <div className="row m-0 d-flex justify-content-center">
                    <span className="footer-copyright">© 2022 Swifty Wallet</span>
                </div>
            </div>
        </footer>
    )
}

export default Footer;