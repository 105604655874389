import React, { useEffect } from "react";
import Footer from "../../components/footer/Footer"
import "../cookies-policy/CookiesPolicy.css";
import PageHeader from "../../components/pages-header/PageHeader";
import { Helmet } from "react-helmet";

const CookiePolicy = () => {
    useEffect(() => {
        (function (d, s, id) {
            var js, tjs = d.getElementsByTagName(s)[0];
            // if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "https://app.termly.io/embed-policy.min.js";
            tjs.parentNode.insertBefore(js, tjs);
        }(document, 'script', 'termly-jssdk'))
    }, []);
    
    return (
        <React.Fragment>
            <Helmet>
                <title>Swifty Wallet | Cookie Policy</title>
                <meta name="title" content="Swifty Wallet | Cookie Policy"/>
                <meta property="og:title" content="Swifty Wallet | Cookie Policy"/>
                <meta name="description" content="A cookie is a small text file that a website stores on your computer or mobile device when you visit the site."/>
                <meta property="og:description" content="A cookie is a small text file that a website stores on your computer or mobile device when you visit the site."/>
                <meta name="keywords" content="swifty, wallet, cookie, policy, legal"/>
                <meta property="og:keywords" content="swifty, wallet, cookie, policy, legal"/>
            </Helmet>
            <PageHeader page={"Cookie Policy"} />
            <div className="cookie-policy-container">
                <div className="center-container">
                    <div name="termly-embed" data-id="461cf963-10f0-42e2-a0de-ca519e79c304" data-type="iframe"></div>
                </div>
            </div>
            <Footer />
        </React.Fragment>

    )
}

export default CookiePolicy
