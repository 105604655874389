import React from "react";
import "./AcademyDropdown.css"
import { Link } from "react-router-dom";

const AcademyDropdown = () =>{
    return(
        <div className="academy-dropdown">
            <Link to="/crypto" className="academy-link" aria-label="what-is-crypto">What is Crypto ?</Link>
            <Link to="/nft" className="academy-link" aria-label="what-is-nft">What is NFT ?</Link>
            <Link to="/how_to_buy" className="academy-link" aria-label="how-to-buy">How to buy ?</Link>
        </div>
    )
}

export default AcademyDropdown