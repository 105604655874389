import React, { useEffect } from "react";
import Footer from "../../components/footer/Footer"
import "../privacy-policy/PrivacyPolicy.css";
import PageHeader from "../../components/pages-header/PageHeader";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
    useEffect(() => {
        (function (d, s, id) {
            var js, tjs = d.getElementsByTagName(s)[0];
            // if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "https://app.termly.io/embed-policy.min.js";
            tjs.parentNode.insertBefore(js, tjs);
        }(document, 'script', 'termly-jssdk'))
    }, []);

    return (
        <React.Fragment>
            <Helmet>
                <title>Swifty Wallet | Privacy Policy</title>
                <meta name="title" content="Swifty Wallet | Privacy Policy"/>
                <meta property="og:title" content="Swifty Wallet | Privacy Policy"/>
                <meta name="description" content="This Privacy Policy is meant to help you understand what information we collect and why we collect it."/>
                <meta property="og:description" content="This Privacy Policy is meant to help you understand what information we collect and why we collect it."/>
                <meta name="keywords" content="swifty, wallet, privacy, policy, legal"/>
                <meta property="og:keywords" content="swifty, wallet, privacy, policy, legal"/>
            </Helmet>
            <PageHeader
                firstPage={"Privacy Policy"}
                secondPage={""}
                thirdPage={""}    
                activeClass={"first"}
            />
            <div className="privacy-policy-container">
                <div className="center-container">
                    <div name="termly-embed" data-id="f88e5c5e-9ab9-47df-8657-2a20f41fbe2c" data-type="iframe"></div>
                </div>
            </div>
            <Footer />
        </React.Fragment>

    )
}

export default PrivacyPolicy
