import React, { useEffect } from "react";
import Footer from "../../components/footer/Footer"
import "../privacy-policy/PrivacyPolicy.css";
import PageHeader from "../../components/pages-header/PageHeader";
import { Helmet } from "react-helmet";

const TermsAndConditions = () => {
    useEffect(() => {
        (function (d, s, id) {
            var js, tjs = d.getElementsByTagName(s)[0];
            // if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "https://app.termly.io/embed-policy.min.js";
            tjs.parentNode.insertBefore(js, tjs);
        }(document, 'script', 'termly-jssdk'))
    }, []);
    
    return (
        <React.Fragment>
            <Helmet>
                <title>Swifty Wallet | Terms and Conditions</title>
                <meta name="title" content="Swifty Wallet | Terms and Conditions"/>
                <meta property="og:title" content="Swifty Wallet | Terms and Conditions"/>
                <meta name="description" content="A Terms and Conditions agreement acts as legal contracts between the company who has the website or mobile app, and the user who accesses it."/>
                <meta property="og:description" content="A Terms and Conditions agreement acts as legal contracts between the company who has the website or mobile app, and the user who accesses it."/>
                <meta name="keywords" content="swifty, wallet, terms, conditions, service, legal, contract"/>
                <meta property="og:keywords" content="swifty, wallet, terms, conditions, service, legal, contract"/>
            </Helmet>
            <PageHeader page={"Terms & Conditions"} />
            <div className="terms-conditions-container">
                <div className="center-container">
                    <div name="termly-embed" data-id="8e2f7d74-c0fd-4c09-be7f-6e37c72e7948" data-type="iframe"></div>
                </div>
            </div>
            <Footer />
        </React.Fragment>

    )
}

export default TermsAndConditions
