import React, {useContext, useState} from "react";
import { ReactComponent as SwiftyWalletLogo } from "../../assets/icons/swifty_wallet.svg"
import "./Header.css"
import Button from "../button/Button";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom"
import AcademyDropdown from "../academy-dropdown/AcademyDropdown";
import {ReactComponent as MenuIcon} from "../../assets/icons/menu.svg"
import {ReactComponent as CloseIcon} from "../../assets/icons/close.svg"
import { AppContext } from "../../context/AppContext";

const Header = () => {
    const [showAcademyDropdown, setShowAcademyDropdown] = useState(false)
    const [showMenuList, setShowMenuList] = useState(false)

    let history = useHistory();

    const renderHomePage = () =>{
        history.push('/')
    }
    const renderDownloadPage = () =>{
        history.push('/download')
    }

    let context = useContext(AppContext);
    let isMobile = context.isMobile;
    // let redirectLink = context.redirectLink;

    const redirectToDownload = () =>{
        history.push('/download')
    }

    return(
        <div className="header-container" 
            style={{ 
                backgroundImage: `url("/assets/images/compress-background-.png")`,
                backgroundSize: "cover"
            }} 
          >
            <div className="position-relative h-100">
                <img src="/assets/images/compress-background-.png" style={{visibility: "hidden", width: "100%"}} className="header-background" alt="header backround"/>
                <div className="header-links">
                    <div>
                        <SwiftyWalletLogo className="swifty-logo" onClick={()=> renderHomePage()}/>
                    </div>
                    {
                        !isMobile ? (
                            <div className="header-link">
                                <div className="header-dropdown-container" onMouseLeave={()=> setShowAcademyDropdown(false)}>
                                    <span 
                                        className="header-link"
                                        onMouseOver={() => setShowAcademyDropdown(true)}
                                    >
                                        Academy
                                    </span>
                                    {
                                        showAcademyDropdown ?
                                            <AcademyDropdown/>
                                        : ""
                                    }
                                </div>
                                <Link to="/features" aria-label="features" className="header-link features-link">Features</Link>
                                <Button 
                                    className="primary btn_get_the_app"
                                    onClick={()=> renderDownloadPage()}
                                    aria-label="get-the-app"

                                >
                                    Get the app
                                </Button>
                            </div>
                        ) : 
                        (
                            <div>
                                <MenuIcon onClick={(e) =>{ setShowMenuList(!showMenuList)}}/>
                            </div>
                        )
                    }
                </div>
                {
                    (showMenuList && isMobile) ? (
                        <div className="menu-list">
                            <div className="close-menu" onClick={(e) => setShowMenuList(false)}>
                                <CloseIcon/>
                            </div>
                            <div className="menu-list-items">
                                <span 
                                    onClick={(e)=>{
                                        setShowMenuList(false)
                                        setShowAcademyDropdown(true)
                                    }}
                                >
                                    Academy
                                </span>
                                <Link to="/features" aria-label="features" >Features</Link>
                                <Link to="/download" aria-label="get-the-app" >Get the App</Link>
                            </div>  
                        </div>
                    ) : ""
                }
                {
                    (showAcademyDropdown && isMobile) ? (
                        <div className="menu-list">
                            <div className="close-menu" onClick={(e) => setShowAcademyDropdown(false)}>
                                <CloseIcon/>
                            </div>
                            <div className="menu-list-items">
                                <Link aria-label="what-is-crypto" to="/crypto">What is Crypto?</Link>
                                <Link aria-label="what-is-nft" to="/nft">What is NFT?</Link>
                                <Link aria-label="how-to-buy" to="/how_to_buy">How to buy?</Link>
                            </div>  
                        </div>
                    ) : ""
                }
                <div className="header-description header-center ">
                    <div className="swifty-wallet">Swifty Wallet</div>
                    <div className="app-info">The app that does it all</div>
                    <Button className="primary btn_download_swifty_wallet" aria-label="download-swfity-wallet" onClick={redirectToDownload}>Download Swifty Wallet</Button>
                </div>
                <div className="center-container position-relative header-center">
                    <img src="/assets/images/compres_home_header_img.png" className="header-phone" alt="swifty wallet phone"/>
                </div>
            </div>
        </div>
    )
}

export default Header;