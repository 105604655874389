import React, { useState, useEffect, useRef} from "react";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow_left.svg"
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow_right.svg"
import "./Slider.css"
import Button from "../button/Button";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const Slider = () => {
    const [slideIndex, setSlideIndex] = useState(0)
    const ref = useRef(null);
    let history = useHistory()
    const slides = [
        {
            logo: <img src="/assets/images/compress_slider.png" alt="create-wallet" />,
            title: 'Quickly Create or Import Multiple Wallets',
            desc: 'Store all your digital assets in one place.'
        },
        {
            logo: <img src="/assets/images/compress_slider_token.png" alt="import-tokens" />,
            title: 'Auto Import all your Tokens and Coins',
            desc: 'Supports over 5,000 assets'
        },
        {
            logo: <img src="/assets/images/compress_slider_receive.png" alt="send-receive" />,
            title: 'Send & Recieve Crypto',
            desc: 'Transfer your crypto assets across the world in minutes, 24 by 7.'
        },
        {
            logo: <img src="/assets/images/compress_slider_dapp.png" alt="connect-dapp" />,
            title: "Connect with your favorite Dapp's",
            desc: 'Connect and explore the world of decentralised apps.'
        },
        {
            logo: <img src="/assets/images/compress_slider_swap.png" alt="swap-tokens" />,
            title: "Quickly Swap Tokens and Coins",
            desc: 'Swap between 4000 + tokens using our built in swapping aggregator.'
        },
        {
            logo: <img src="/assets/images/compress_slider_market.png" alt="market-updates" />,
            title: "Get Notified of Market Updates",
            desc: 'Be in the know! Receive the latest crypto market conditions.'
        },
    ]
    
    const changeSlide = (e) =>{
        let mode = e.currentTarget.dataset.mode;
        if(mode === "left"){
            if(slideIndex === 0){
                setSlideIndex(5)
            }else{
                if(slideIndex <= 5){
                    setSlideIndex(prevState => prevState - 1)
                }
            }
        }else if(mode === "right"){
            if(slideIndex === 0){
                setSlideIndex(prevState => prevState + 1)
            }else{
                if(slideIndex < 5){
                    setSlideIndex(prevState => prevState + 1)
                }else if(slideIndex === 5){
                    setSlideIndex(0)
                }
            }
        }

    }

    const renderFeaturesPage = () => {
        history.push('/features')
    }

    useEffect(() => {
        setInterval(() => {
            if(ref.current){
                ref.current.click();
            }
        }, 7000);
    },[])

    return (
        <div className="slider-container">
            <div className="row slider-reverse">
                <div className="col-12 col-lg-6 col-xl-6 d-flex justify-content-center slider-logo">
                    {slides[slideIndex].logo}
                </div>
                <div className="col-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column slider-description-container">
                    <div className="slider-text">
                        <div className="slider-title"> {slides[slideIndex].title}</div>
                        <div className="slider-description"> {slides[slideIndex].desc}</div>
                    </div>
                    <div className="all-features">
                        <Button className="primary" aria-label="all-futures" onClick={renderFeaturesPage }>All Features </Button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-lg-6 col-xl-6"></div>
                <div className="col-12 col-lg-6 col-xl-6">
                    <div className="slider-navigate">
                        <div
                            className="sider-arrow left"
                            data-mode="left"
                        ref={ref}
                        onClick={(e)=>{
                            changeSlide(e)
                        }}
                        >
                            <ArrowLeft/>
                        </div>
                        <div
                            className="sider-arrow right"
                            data-mode="right"
                            onClick={(e)=>{
                                changeSlide(e)
                            }}
                        >
                            <ArrowRight/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Slider