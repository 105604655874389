import React from "react";
import PageHeader from "../../components/pages-header/PageHeader";
import Footer from "../../components/footer/Footer";
import "./Faq.css"
import { Accordion } from "react-bootstrap";
import FaqData from "../../data/FaqData";
import Parser from 'html-react-parser';
import { Helmet } from "react-helmet";

const Faq = () => {

    FaqData.sort((a, b) => a.Q < b.Q ? -1 : 1)

    return(
        <React.Fragment>
            <Helmet>
                <title>Swifty Wallet | FAQ</title>
                <meta name="title" content="Swifty Wallet | FAQ"/>
                <meta property="og:title" content="Swifty Wallet | FAQ"/>
                <meta name="description" content="View the frequently asked questions and answers about blockchain, cryptocurrency and the Swifty Wallet."/>
                <meta property="og:description" content="View the frequently asked questions and answers about blockchain, cryptocurrency and the Swifty Wallet."/>
                <meta name="keywords" content="faq, frequently, questions, queries, help, information, support"/>
                <meta property="og:keywords" content="faq, frequently, questions, queries, help, information, support"/>
            </Helmet>
            <PageHeader
                firstPage={"Frequently Asked Questions"}
                secondPage={""}
                thirdPage={""}    
                activeClass={"first"}
            />
            <div className="faq-container">
                <div className="center-container">
                    <div className="row academy-description">
                        <div className="col-6">
                            <div className="academy-title">We are here for all your inquiries</div>
                            <label className="academy-label">Can't find the answer to your question? Check out the contact us page or drop us an email at hello@swifty.global</label>
                        </div>
                            <img className="faq-icon" src="/assets/images/compress_faq.png" alt="contact-us-phones" />
                    </div>
                    <div className="row faq-questions">
                        <Accordion>
                            {
                                FaqData.map((faq, index)=>{
                                    return(
                                        <Accordion.Item eventKey={index} key={index}>
                                            <Accordion.Header>{faq.Q}</Accordion.Header>
                                            <Accordion.Body>{Parser(faq.A)}</Accordion.Body>
                                        </Accordion.Item>
                                    )
                                })
                            }
                        </Accordion>
                    </div>
                </div>
            </div>
            <Footer/>
        </React.Fragment>
    )
}

export default Faq