import React, { useContext } from "react";
import Footer from "../../components/footer/Footer";
import { ReactComponent as AppStoreLogo } from "../../assets/icons/apple.svg";
import { ReactComponent as GoogleStoreLogo } from "../../assets/icons/google.svg";
import "../download/download.css";
import Button from "../../components/button/Button";
import PageHeader from "../../components/pages-header/PageHeader";
import { AppContext } from "../../context/AppContext";
import { Helmet } from "react-helmet";

const Download = () => {
  let context = useContext(AppContext);
  let appleLink = context.appleLink;
  let googleLink = context.googleLink;

  const redirectAppStoreLink = () => {
    window.open(appleLink, "_blank");
  };

  const redirectPlayStoreLink = () => {
    window.open(googleLink, "_blank");
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Swifty Wallet | Downloads</title>
        <meta name="title" content="Swifty Wallet | Downloads" />

        <meta
          property="og:url"
          content="https://www.swifty-wallet.com/download"
        />
        <meta property="og:type" content="page" />
        <meta property="og:title" content="Swifty Wallet | Downloads" />
        <meta
          property="og:description"
          content="Download the Swifty Wallet today. Swifty Wallet is a non-custodial wallet mobile app."
        />
        <meta
          name="keywords"
          content="download, iOS, Android, crypto, wallet, buy, swap, staking, NFT"
        />
      </Helmet>
      <PageHeader
        firstPage={"Download"}
        secondPage={""}
        thirdPage={""}
        activeClass={"first"}
      />
      <div className="download-container">
        <div className="center-container">
          <div className="row download-content">
            <div className="col-12 d-flex justify-content-between download-content-mobile">
              <div className="col-6">
                <h3 className="download-finance">Swifty Wallet App</h3>
                <label className="download-finance-lable">
                  Download the app now!
                </label>
                <label className="download-finance-paragraph">
                  Available on both iOS and Android. Get the Swifty Wallet
                  today.
                </label>
                <div className="col-12 download-app-buttons">
                  <Button
                    className="secondary btn-install-app btn-app-store"
                    ariaLabel="app-store"
                    onClick={redirectAppStoreLink}
                  >
                    <div>
                      <AppStoreLogo />
                    </div>
                  </Button>
                  <Button
                    className="secondary btn-install-app btn-play-store"
                    ariaLabel="google-store"
                    onClick={redirectPlayStoreLink}
                  >
                    <div>
                      <GoogleStoreLogo />
                    </div>
                  </Button>
                </div>
              </div>
              <div
                className="col-6 d-flex justify-content-end download-phone-container"
                style={{ height: "fit-content" }}
              >
                <img
                  className="download-phones download-icon"
                  src="/assets/images/compress_download.png"
                  alt="download-phones"
                />
              </div>
            </div>
            <p className="download-body-text">
              {" "}
              Swifty Wallet is a non-custodial wallet mobile app that lets
              customers store their own crypto and use dapps. Through the mobile
              app, you can connect with thousands of decentralised apps (dapp),
              buy and swap crypto, earn interest through staking and purchase
              NFTs. The Swifty Wallet already supports the major chains i.e.
              ETH, BNB, AVAX, ARBITRUM, FTM, MATIC and SOL
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Download;
