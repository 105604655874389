import React from "react";
import Footer from "../../components/footer/Footer";
import "../about-us/AboutUs.css";
import PageHeader from "../../components/pages-header/PageHeader";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Swifty Wallet | About Us</title>
        <meta name="title" content="Swifty Wallet | About Us" />
        <meta property="og:title" content="Swifty Wallet | About Us" />
        <meta
          name="description"
          content="Swifty Global is building an ecosystem of connected applications. Swifty Wallet is our crypto mobile application."
        />
        <meta
          property="og:description"
          content="Swifty Global is building an ecosystem of connected applications. Swifty Wallet is our crypto mobile application."
        />
        <meta
          name="keywords"
          content="swifty, global, about, history, wallet"
        />
        <meta
          property="og:keywords"
          content="swifty, global, about, history, wallet"
        />
      </Helmet>
      <PageHeader
        firstPage={"About"}
        secondPage={""}
        thirdPage={""}
        activeClass={"first"}
      />
      <div className="about-us-container">
        <div className="center-container">
          <div className="row d-flex justify-content-between about-us-content">
            <div className="col-6 about-us-text about-us-text-mobile">
              <p className="about-us-text-margin">
                Swifty is building an ecosystem of connected applications.
              </p>
              <p className="about-us-text-margin">
                We started our journey back in 2020 and found the
                crypto-ecosystem too complicated for a new user.
              </p>
              <p className="about-us-text-margin">
                We wanted to create a simple way for users to buy, swap and
                transact using a transparent financial system.
              </p>
              <p className="about-us-text-margin">
                The main goal is for users to have one wallet, to be in control
                of their accounts and to connect with their favorite blockchain
                enabled websites.
              </p>
            </div>
            <div className="col-6 about-us-text">
              <img
                src="/assets/images/compress_about.png"
                className="about-us-phones"
                alt="about_us_phones"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default AboutUs;
