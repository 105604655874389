import React from "react";
import "../crypto/Crypto.css"
import PageHeader from "../../../components/pages-header/PageHeader";
import Footer from "../../../components/footer/Footer";
import { Helmet } from "react-helmet";

const Nft = () =>{
    return(
        <React.Fragment>
            <Helmet>
                <title>Swifty Wallet | What is an NFT?</title>
                <meta name="title" content="Swifty Wallet | What is an NFT?"/>
                <meta name="description" content="Non-Fungible token are rare digital assets where the ownership is stored on a blockchain."/>
                <meta name="keywords" content="NFT, non-fungible, digital, artwork, music, opensea, rariable"/>
            </Helmet>
            <PageHeader
                firstPage={"What is Crypto?"}
                secondPage={"What is NFT?"}
                thirdPage={"How To Buy?"}    
                activeClass={"second"}
                clickable={true}
                firstLink={"/crypto"}
                secondLink={"/nft"}
                thirdLink={"/how_to_buy"}
            />
            <div className="academy-container">
                <div className="center-container">
                    <div className="row academy-description">
                        <div className="col-6">
                            <div className="academy-title">NFT's are now being used to transfer ownership of anything digital.</div>
                            <label className="academy-label">This guide will give you a basic understanding of what an NFT is.</label>
                        </div>
                        <img src="/assets/images/compress_nft.png" alt="how-to-buy" className="nft-icon"/>
                    </div>
                    <div className="row academy-row">
                        <div className="col-12">
                            <div className="academy-bold-title">
                                NFT's in a snapshot 
                            </div>
                            <label className="academy-bold-label">
                                NFT is a non fungible token. NFT's are digital artwork, music, videos etc are unique digital assets in which the ownership rights are stored on a blockchain.
                            </label>
                        </div>
                    </div>
                </div>
                {/* <div className="academy-video-container academy-row">
                    <img src="/assets/images/nft_video.png" alt="nft"/>
                </div> */}
                <div className="center-container">
                    <div className="row academy-row">
                        <div className="col-12">
                            <div className="academy-bold-title">
                                 What is an NFT and how does it work?
                            </div>
                            <label className="academy-bold-label">
                                An NFT stands for a Non Fungible Token. “Non Fungible” means that it’s unique and can’t be replicated or replaced with something else. Trading Crypto is fungible i.e. if you traded ETH for another ETH you would have exactly the same thing.                          
                            </label>
                        </div>
                    </div>
                    <div className="row academy-row">
                        <img src="/assets/images/nft_samples.png" alt="nft"/>
                    </div>
                    <div className="row academy-row">
                        <div className="col-12 col-lg-6 col-xl-6">
                            <ul>
                                <li>
                                    Majority of NFT's are on the Ethereum blockchain but you are also able to find NFT's on other networks like Polygon and Binance Smart Chain.
                                </li>
                                <li>
                                    NFT's have been around since 2014 but started to gain more traction in 2021 where $25 billion was spent on purchasing and trading NFT's. Majority of the sales and trades happened on the marketplace called opensea.io.
                                </li>
                                <li>
                                    An NFT can be anything digital but a lot of hyper has been around selling digital artwork. Other NFT's can be images, music, videos, tickets etc.                               
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-lg-6 col-xl-6">
                            <ul>
                                <li>
                                    NFT ledgers also on the blockchain claim to provide a public certificate of authenticity or proof of ownership.
                                </li>
                                <li>
                                    Buying an NFT does not grant copyright or intellectual property right to the digital asset a token represents. NFT is merely a proof of ownership.
                                </li>
                                <li>
                                    Some of the more famous collectibles are the Bored Ape NFT's. Celebrities such as Justin Beiber, Paris Hilton and Gwyneth Palthrow have purchased a Bored Ape NFT. This collectible promises access to physcial world clubs, places in the metaverse and IP potential.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </React.Fragment>
    )
}

export default Nft