import React, {useState, useRef} from "react";
import "../contact-us/ContactUs.css";
import PageHeader from "../../components/pages-header/PageHeader";
import Footer from "../../components/footer/Footer";
import Button from "../../components/button/Button";
import { ReactComponent as FullName } from "../../assets/icons/fullname.svg"
import { ReactComponent as EmailAddress } from "../../assets/icons/emailaddres.svg"
import { ReactComponent as LeaveComment } from "../../assets/icons/message.svg"
import { ReactComponent as ContactUsPhone } from "../../assets/icons/contactusphone.svg"
import { ReactComponent as Email } from "../../assets/icons/@.svg"
import { ReactComponent as Location } from "../../assets/icons/location.svg"
import { ReactComponent as TickIcon } from "../../assets/icons/tick.svg";
import { Helmet } from "react-helmet";
import axios from "axios";

const ContactUs = () => {
    const [fullName, setFullName] = useState('')
    const [emailAddress, setEmailAddress] = useState('')
    const [message, setMessage] = useState('')
    const [showSuccessMessage, setShowSuccessMessage] = useState(false)
    const [showFullNameRequired, setShowFullNameRequired] = useState(false)
    const [showEmailRequired, setShowEmailRequired] = useState(false)
    const [showMessageRequired, setShowMessageRequired] = useState(false)
    const [disableButton, setButtonDisable] = useState(false)

    let fullNameRef = useRef()
    let emailRef = useRef()
    let messageRef = useRef()

    const submitContactUs = (e) =>{
        e.preventDefault()
        
        if(fullName === ""){
            setShowFullNameRequired(true)
            return false
        }else{
            setShowFullNameRequired(false)
        }

        if(emailAddress === ""){
            setShowEmailRequired(true)
            return false
        }else{
            setShowEmailRequired(false)
        }

        if(message === ""){
            setShowMessageRequired(true)
            return false
        }else{
            setShowMessageRequired(false)
        }

        let body = {
            "email": emailAddress,
            "name": fullName,
            "message": message,
            "subject": "Swifty Wallet - Website"
        }

        setButtonDisable(true)
        axios.put('https://cjy8wg4378.execute-api.eu-west-1.amazonaws.com/dev/v1/feedback', body)
        .then(response => {
            if(response.status === 200){
                setShowSuccessMessage(true)
                setButtonDisable(false)
            }
        })
        .catch(err =>{
            console.log(err)
        })
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Swifty Wallet | Contact Us</title>
                <meta name="title" content="Swifty Wallet | Contact Us"/>
                <meta property="og:title" content="Swifty Wallet | Contact Us"/>
                <meta name="description" content="Contact Us. Have a question, query, issue or feature request? Get in contact with the team at Swifty Global."/>
                <meta property="og:description" content="Contact Us. Have a question, query, issue or feature request? Get in contact with the team at Swifty Global."/>
                <meta name="keywords" content="contact, question, query, feature, bug, issue, problem, number, email, message, support, address"/>
                <meta property="og:keywords" content="contact, question, query, feature, bug, issue, problem, number, email, message, support, address"/>
            </Helmet>
            <PageHeader page="Contact Us" />
            <div className="contact-us-container">
                <div className="center-container">
                    <div className="row contact-content">
                        <div className="col-12 d-flex justify-content-between">
                            <div className="col-6 contact-us-content">
                                <h3 className="contact-us-title">We are here for all your inquiries</h3>
                                <label className="contact-us-paragraph">Question, query, issue, feature request or just to say hello. Please use the form below and we will do our best to answer you as quickly as possible.</label>
                            </div>
                            <div className="col-6 contact-us-phones-img">
                                <img className="contact-us-phones" src="/assets/images/compress_contact_us.png" alt="contact-us-phones" />
                            </div>
                        </div>
                        {
                            !showSuccessMessage ? (
                                <form id="contact-us-form" 
                                    onSubmit={ (e)=> {
                                            if(!disableButton){
                                                submitContactUs(e)
                                            }
                                        }
                                    }
                                >
                                    <div className={showFullNameRequired ? "col-12 contact-us-input required" : "col-12 mb-4 contact-us-input"} ref={fullNameRef}>
                                        <FullName className="full-name-phones"/>
                                        <input
                                            type="text"
                                            className="contact-us-input"
                                            id="input_contact-us-fullname"
                                            placeholder="Full Name"
                                            onChange={(e) => setFullName(e.target.value)}
                                        />
                                    </div>
                                    {
                                        showFullNameRequired ? (
                                            <div className="required-input mb-4">Full Name is required</div>
                                        ) : ""
                                    }
                                    <div className={showEmailRequired ? "col-12 contact-us-input required" : "col-12 mb-4 contact-us-input"} ref={emailRef}>
                                        <EmailAddress className="email-icon"/>
                                        <input
                                            type="email"
                                            className="contact-us-input"
                                            placeholder="Email Address"
                                            onChange={(e) => setEmailAddress(e.target.value)}
                                        />
                                    </div>
                                    {
                                        showEmailRequired ? (
                                            <div className="required-input mb-4">Email Address is required</div>
                                        ) : ""
                                    }
                                    <div className={showMessageRequired ? "col-12 mb-2 textarea textarea-input required" : "col-12 mb-4 textarea textarea-input"} ref={messageRef}>
                                        <LeaveComment className="message-icon"/>
                                        <textarea
                                            className="contact-us-textarea"
                                            placeholder="Leave a message"
                                            onChange={(e) => setMessage(e.target.value)}
                                        ></textarea>
                                    </div>
                                    {
                                        showMessageRequired ? (
                                            <div className="required-input mb-4">Message is required</div>
                                        ) : ""
                                    }
                                    <div className="row m-0 mt-5 justify-content-end">
                                        <div className="col-12 col-lg-8 col-xl-8 d-flex justify-content-end">
                                            {
                                                !disableButton ? (
                                                <Button
                                                    className={disableButton ? "contact-us-button disabled" : "contact-us-button"}
                                                    type="submit"
                                                    aria-label="submit"
                                                >
                                                    Submit
                                                </Button>
                                                ) : ""
                                            }
                                            
                                        </div>
                                    </div>
                                </form>
                            ) : (
                                <div className="row m-0">
                                    <div className="col-12 success-submited-form">
                                        <TickIcon style={{height: "20px", width: "20px", marginRight: "15px"}}/>
                                        Your message has been sent. We try to respond to all messages within 72 hours.
                                    </div>
                                </div>
                            )
                        }
                        <div className="col-12 contact-us-buttons">
                            <div className="col-4 phone-contact-us-mobile">
                                <a href="tel:+ 44(0) 204 542 4511">
                                    <ContactUsPhone className="phone-contact-us"/>
                                    <label className="phone-number-label">+ 44(0) 204 542 4511</label></a>
                            </div>
                            <div className="col-4 phone-contact-us-mobile">
                                <a href="mailto:hello@swifty.wallet">
                                    <Email className="email-contact-us"/>
                                    <label className="email-label">hello@swifty.wallet</label></a>
                            </div>
                            <div className="col-4 location-contact-us-mobile">
                                <a href={`https://www.google.com/maps/place/Shop+71,+ALG+ID+Cards+t%2Fa+The+Lanyard,+75+Shelton+St,+London+WC2H+9JQ,+UK/@51.5149037,-0.1257729,17z/data=!3m1!4b1!4m5!3m4!1s0x487604ccab37652b:0x22220c3f07824033!8m2!3d51.5149037!4d-0.1235842`} target="_blank" rel="noreferrer">
                                    <Location />
                                    <label className="location-label">71-75, Shelton Street, Covent Garden, London, WC2H 9JQ, United Kingdom</label>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>

    )
}

export default ContactUs