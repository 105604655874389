import React, {useContext, useState} from "react";
import "./PageHeader.css"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ReactComponent as SwiftyWalletLogo } from "../../assets/icons/swifty_wallet.svg"
import {ReactComponent as MenuIcon} from "../../assets/icons/menu.svg"
import {ReactComponent as CloseIcon} from "../../assets/icons/close.svg"
import Button from "../button/Button";
import {Link} from "react-router-dom"
import AcademyDropdown from "../academy-dropdown/AcademyDropdown";
import { AppContext } from "../../context/AppContext";


const PageHeader = (props) =>{
    const [showAcademyDropdown, setShowAcademyDropdown] = useState(false)
    const [showMenuList, setShowMenuList] = useState(false)

    let history = useHistory();
    let context = useContext(AppContext);
    let isMobile = context.isMobile;

    const renderHomePage = () =>{
        history.push('/')
    }

    const renderDownloadPage = () =>{
        history.push('/download')
    }

    return(
       <div className="page-header"             
            style={{ 
                backgroundImage: `url("/assets/images/page_header_background.png")`,
                backgroundSize: "cover"
            }}
        >
            <div className="position-relative h-100">
                <img src="/assets/images/page_header_background.png" style={{visibility: "hidden", width: "100%"}} className="header-background" alt="header backround"/>
                <div className="header-links">
                    <div
                        onClick={()=> renderHomePage()}
                    >
                        <SwiftyWalletLogo className="swifty-logo"/>
                    </div>
                    {
                        !isMobile ? (
                            <div className="header-link">
                                <div className="header-dropdown-container" onMouseLeave={()=> setShowAcademyDropdown(false)}>
                                    <span 
                                        className="header-link"
                                        onMouseOver={() => setShowAcademyDropdown(true)}
                                    >
                                        Academy
                                    </span>
                                    {
                                        showAcademyDropdown ?
                                            <AcademyDropdown/>
                                        : ""
                                    }
                                </div>
                                <Link to="/features" className="header-link features-link" aria-label="features">Features</Link>
                                <Button 
                                    className="primary btn_get_the_app"
                                    onClick={()=> renderDownloadPage()}
                                    aria-label="get-the-app"
                                >
                                    Get the app
                                </Button>
                            </div>

                        ) : 
                        (
                            <div>
                                <MenuIcon onClick={(e) =>{ setShowMenuList(!showMenuList)}}/>
                            </div>
                        )
                    }
                </div>
                {
                    (showMenuList && isMobile) ? (
                        <div className="menu-list">
                            <div className="close-menu" onClick={(e) => setShowMenuList(false)}>
                                <CloseIcon/>
                            </div>
                            <div className="menu-list-items">
                                <span 
                                    onClick={(e)=>{
                                        setShowMenuList(false)
                                        setShowAcademyDropdown(true)
                                    }}
                                >
                                    Academy
                                </span>
                                <Link aria-label="features" to="/features">Features</Link>
                                <Link aria-label="get-the-app" to="/download">Get the App</Link>
                            </div>  
                        </div>
                    ) : ""
                }
                {
                    (showAcademyDropdown && isMobile) ? (
                        <div className="menu-list">
                            <div className="close-menu" onClick={(e) => setShowAcademyDropdown(false)}>
                                <CloseIcon/>
                            </div>
                            <div className="menu-list-items">
                                <Link aria-label="what-is-crypto" to="/crypto">What is Crypto?</Link>
                                <Link aria-label="what-is-nft" to="/nft">What is NFT?</Link>
                                <Link aria-label="how-to-buy" to="/how_to_buy">How to buy?</Link>
                            </div>  
                        </div>
                    ) : ""
                }
                {
                    !isMobile ? (
                        <div className="header-page-name header-center">
                            <span 
                                className={ props.activeClass ?  props.activeClass === "first" ? "active" : '' : "active" }
                                onClick={(e)=>{
                                    if(props.clickable){
                                        history.push(props.firstLink)
                                    }
                                }}
                            > 
                                {
                                    props.firstPage ? props.firstPage : props.page
                                }
                            </span>
                            <span 
                                className={props.activeClass === "second" ? "active" : '' }
                                onClick={(e)=>{
                                    if(props.clickable){
                                        history.push(props.secondLink)
                                    }
                                }}
                            >
                                {props.secondPage}
                            </span>
                            <span 
                                className={props.activeClass === "third" ? "active" : '' }
                                onClick={(e)=>{
                                    if(props.clickable){
                                        history.push(props.thirdLink)
                                    }
                                }}
                            >
                                {props.thirdPage}
                            </span>
                        </div>
                    ) : (
                        <div className="header-page-name header-center">
                            <span>
                                {
                                    props.firstPage ? props.firstPage : props.page
                                }
                            </span>
                        </div>
                    )
                }
            </div>
       </div>
    )
}

export default PageHeader