import React from "react";
import "./Overview.css"

const Overview = ({icon, title, text}) =>{
    return(
        <div className="overview-container">
            <div className="overview-icon">
                {icon}
            </div>
            <div className="overview-border">
                <span className="overview-label">{title}</span>
                <span className="overview-info">
                    {text}
                </span>
            </div>
        </div>
    )
}

export default Overview