import React from "react";
import "../crypto/Crypto.css"
import PageHeader from "../../../components/pages-header/PageHeader";
import Footer from "../../../components/footer/Footer";
// import { AppContext } from "../../../context/AppContext";
import { Helmet } from "react-helmet";

const HowToBuy = () =>{
    // let context = useContext(AppContext);
    // let isMobile = context.isMobile;

    return(
        <React.Fragment>
            <Helmet>
                <title>Swifty Wallet | How to Buy?</title>
                <meta name="title" content="Swifty Wallet | How to Buy?"/>
                <meta name="description" content="Purchase crypto using a debit or credit card through the Swifty Wallet."/>
                <meta name="keywords" content="purchase, buy, crypto, token, bitcoin, ethereum, usdt, usdc"/>
            </Helmet>
            <PageHeader
                firstPage={"What is Crypto?"}
                secondPage={"What is NFT?"}
                thirdPage={"How To Buy?"}    
                activeClass={"third"}
                clickable={true}
                firstLink={"/crypto"}
                secondLink={"/nft"}
                thirdLink={"/how_to_buy"}
            />
            <div className="academy-container">
                <div className="center-container">
                    <div className="row academy-description">
                        <div className="col-6">
                            <div className="academy-title">Where to buy digital currencies, tokens and NFT's i.e. artwork? </div>
                            <label className="academy-label">This guide will give you a basic understanding of how to buy.</label>
                        </div>
                        <img src="/assets/images/compress_how_to_buy.png" alt="how-to-buy" className="how-to-buy-icon"/>
                    </div>
                    <div className="row academy-row">
                        <div className="col-12">
                            <div className="academy-bold-title">
                                How to buy crypto with a debit or credit card?
                            </div>
                            <label className="academy-bold-label">
                                We recommend downloading the Swifty Wallet application from either the IOS App Store or Android Play Store. After downloading and running through the quick wizard click the buy icon on the bottom menu where you can purchase a number of mainstream crypto coins and tokens.
                            </label>
                        </div>
                    </div>
                </div>
                {/* <div className="academy-video-container academy-row">
                    <img src="/assets/images/how_to_buy_video.png" alt="nft" className="how_to_buy_video"/>
                </div> */}
                <div className="center-container">
                    <div className="row academy-row">
                        <div className="col-12">
                            <div className="academy-bold-title">
                                The coin or token I want isn't available to buy?
                            </div>
                            <label className="academy-bold-label">
                                Swifty Wallet like most exchanges only support the buying of main stream coins. As of today the Swifty Wallet supports the following tokens ETH, USDC, USDT, AVAX, BNB, MATIC, and SOL.                            
                            </label>
                            <label className="academy-bold-label">
                                Tokens such as AAVE, LINK, MANA, SHIB and UNI are tokens that are built on top of a blockchain network like Ethereum. To purchase these tokens you must first own Ethereum (ETH) and then you can swap the token with our inbuilt swapping function or a decentralised exchange like Uniswap.
                            </label>
                            <label className="academy-bold-label">
                                The Swifty Wallet swapping function supports the swapping of tokens on both the BNB and ETH networks. You must first check to see what network the token is on before buying the network coin like ETH or BNB as some tokens may not be available on all chains.                            
                            </label>
                            <label className="academy-bold-label">
                                www.coinmarketcap.com and www.coingecko.com are some good websites to get further details about coins and tokens and to find out what network they are built on.                            </label>
                        </div>
                    </div>
                    <div className="row academy-row">
                        <div className="col-12">
                            <div className="academy-bold-title">
                                Can I buy coins or tokens with a bank transfer?
                            </div>
                            <label className="academy-bold-label">
                                Swifty Wallet currently doesn't support bank transfers but this is something we are looking to add in the future. Swifty Wallet team are working to bringing bank accounts and virtual cards to the application so you can also spend your crypto holding using a pre-paid debit card. This can be used across point-of-sale terminals and e-commerce sites as you would with a normal debit or credit card.
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </React.Fragment>
    )
}

export default HowToBuy