import React, { useContext } from "react";
import PageHeader from "../../components/pages-header/PageHeader";
import Footer from "../../components/footer/Footer";
import Button from "../../components/button/Button";
import "./Features.css"
import { ReactComponent as WalletLogo } from "../../assets/icons/wallet_connect_logo.svg";

import { AppContext } from "../../context/AppContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Helmet } from "react-helmet";

const Features = () => {
    let history = useHistory();

    let context = useContext(AppContext);
    // let redirectLink = context.redirectLink;

    const redirectToDownload = () => {
        history.push('/download')
    }

    let isMobile = context.isMobile;

    return (
        <React.Fragment>
            <Helmet>
                <title>Swifty Wallet | Features</title>
                <meta name="title" content="Swifty Wallet | Features" />
                <meta name="description" content="Swifty Wallet is the only wallet you will ever need. Store, Buy, Swap and connect to your favorite dapps." />
                <meta name="keywords" content="multi-chain, import, send, receive, wallet connect, swap, token, coins, NFT" />
            </Helmet>
            <PageHeader
                firstPage={"Features"}
                secondPage={""}
                thirdPage={""}
                activeClass={"first"}
            />
            <div className="features-container">
                <div className="center-container">
                    <div className="row m-0">
                        <div className="col-12 d-flex justify-content-between">
                            <div className="col-6">
                                <h3 className="features-swifty-wallet">Swifty Wallet</h3>
                                <label className="features-swifty-wallet-label">The only wallet you will ever need.</label>
                                <label className="features-swifty-wallet-paragraph">Store all your crypto in one app <br /> Track 5,000 + assets <br /> Buy and swap crypto <br /> Connect to the decentralised web</label>
                                <div className="futures-download-app-buttons">
                                    <Button
                                        className="dark btn_download_swifty_wallet"
                                        onClick={redirectToDownload}
                                        aria-label="download"
                                    >
                                        Download Swifty Wallet
                                    </Button>
                                </div>
                            </div>
                            <div className="col-6 d-flex justify-content-end">
                                <img className="features-icon" src="/assets/images/compress_futures.png" alt="iphone" />
                            </div>
                        </div>
                    </div>
                    <div className="row feature-create-wallets">
                        {
                            !isMobile ? (
                                <div className="col-6"></div>
                            ) : ''
                        }
                        {
                            isMobile ?
                                (
                                    <div className="col-12 mobile-text">
                                        <h3 className="features-swifty-wallet">Quickly Create or Import Multiple Wallets</h3>
                                        <label className="features-swifty-wallet-paragraph">Get started today using the Swifty Wallet or import an already existing wallet.<br /><br />Swifty Wallet combines multiple chains into one easy to use app.</label>
                                    </div>
                                ) :
                                <div className="col-6">
                                    <h3 className="features-swifty-wallet">Quickly Create or Import Multiple Wallets</h3>
                                    <label className="features-swifty-wallet-paragraph create-wallet-quickly m-0">Get started today using the Swifty Wallet or import an already existing wallet.<br /><br />Swifty Wallet combines multiple chains into one easy to use app.</label>
                                </div>
                        }
                    </div>
                </div>
                <div className="m-0 features-banner" style={{ backgroundImage: "url(/assets/images/features_background.svg)", display: "block" }}>
                    <div className="center-container">
                        <img className="features-create-wallets-icon" src="/assets/images/compress_futures_create.png" alt="features-create-wallets-icon" />
                    </div>
                    <div className="center-container">
                        <div className="row features-import-tokens">
                            <div className="col-12 col-lg-4 col-xl-4">
                                <h3 className="features-swifty-wallet light">Auto Import all your Tokens and Coins</h3>
                                <label className="features-swifty-wallet-paragraph light">Swifty automatically detects coins that have been added to your wallet.<br /><br />You can also hide unwanted coins in settings.</label>
                            </div>
                            <div className="col-12 col-lg-8 col-xl-8 features-import-tokens-image-container">
                                <img className="features-import-tokens-icon" src="/assets/images/compress_futures_import.png" alt="iphone" />
                            </div>
                        </div>
                        <div className="row features-send-receive-crytpo">
                            <div className="col-12 features-send-receive-description">
                                <h3 className="features-swifty-wallet light">Send & Recieve Crypto</h3>
                                <label className="features-swifty-wallet-paragraph light h-auto features-send-receive-paragraph">With Swifty Wallet you can send and recieve crypto across majority of mainstream networks. Swifty Wallet also brings in all your transaction history so you can keep track of your transactions.</label>
                            </div>
                        </div>
                        <div className="row m-0 justify-content-center">
                            <img className="send-recive-icon" src="/assets/images/compress_futures_recive.png" alt="iphone" />
                        </div>
                    </div>
                </div>
                <div className="center-container">
                    <div className="row features-connect-dapp features-dapp">
                        <div className="col-12 col-xl-6 col-lg-6 features-wallet-connect">
                            <img className="phones-future" src="/assets/images/compress_futures_dapp.png" alt="iphone" />
                        </div>
                        <div className="col-12 col-xl-6 col-lg-6 dapp-mobile-favorite">
                            <h3 className="features-swifty-wallet">Connect with your favorite Dapp's using </h3>
                            <WalletLogo className="wallet-logo" />
                            <label className="features-swifty-wallet-paragraph">Swifty Wallet has wallet connect built into the app. Connect to your favorite sites where you see the wallet connect icon. </label>
                        </div>
                    </div>
                </div>
                <div className="center-container">
                    <div className="row features-connect-dapp features-connect-dapp-quickly">
                        <div className="col-12 col-lg-6 col-xl-6">
                            <h3 className="features-swifty-wallet">Quickly Swap Tokens and Coins</h3>
                            <label className="features-swifty-wallet-paragraph">Using our built in decentralised exchange aggregator users can swap between tokens and coins seemlessly within the mobile app.</label>
                        </div>
                        <div className="col-12 col-lg-6 col-xl-6 swap-tokens-container">
                            <img className="qucikly-swap-tokens" src="/assets/images/compress_futures_swap.png" alt="iphone" />
                        </div>
                    </div>
                </div>
                <div className="center-container">
                    <div className="row features-connect-dapp new-future features-connect-dapp-new-future justify-content-center flex-column">
                        <div className="col-12">
                            <h3 className="features-swifty-wallet features-swifty-wallet-mobile">New Features (Coming Soon)</h3>
                            <label className="features-swifty-wallet-paragraph h-auto features-send-receive-paragraph">Swifty Team are already working on the next release of the swifty wallet which will bring push notifications, purchasing and storage of NFTs, transfer protection features and more.</label>
                        </div>
                        <div className="col-12 d-flex justify-content-center">
                            <img className="new-futures" src="/assets/images/compress_futures_new.png" alt="iphone" />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    )
}

export default Features