import React from "react";

const Button = (props) =>{
    return(
        <button
            className={props.className}
            aria-label={props.ariaLabel}
            onClick={props.onClick}
        >
            {props.children}
        </button>
    )
}

export default Button;