import React, { useContext } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { ReactComponent as AppStoreLogo } from "../../assets/icons/apple.svg";
import { ReactComponent as GoogleStoreLogo } from "../../assets/icons/google.svg";
import "./Index.css";
import Button from "../../components/button/Button";
import Overview from "../../components/overview/Overview";
import { ReactComponent as IntroductionIcon } from "../../assets/icons/introduction.svg";
import { ReactComponent as DescriptionIcon } from "../../assets/icons/description.svg";
import { ReactComponent as RequirementsIcon } from "../../assets/icons/requirements.svg";
import Chart from "../../components/chart/Chart";
import Slider from "../../components/slider/Slider";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { Helmet } from "react-helmet";

const Index = () => {
  let context = useContext(AppContext);
  let isMobile = context.isMobile;
  let appleLink = context.appleLink;
  let googleLink = context.googleLink;

  const redirectAppStoreLink = () => {
    window.open(appleLink, "_blank");
  };

  const redirectPlayStoreLink = () => {
    window.open(googleLink, "_blank");
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Swifty Wallet | Home</title>
        <meta name="title" content="Swifty Wallet | Home" />
        <meta property="og:title" content="Swifty Wallet | Home" />
        <meta
          name="description"
          content="Swifty Wallet is a muti-chain wallet that puts you in control of your crypto, keys and data. Buy, Swap and Watch your favorite crypto coins and tokens. NFT's coming soon."
        />
        <meta
          property="og:description"
          content="Swifty Wallet is a muti-chain wallet that puts you in control of your crypto, keys and data. Buy, Swap and Watch your favorite crypto coins and tokens. NFT's coming soon."
          data-react-helmet="true"
        />
        <meta
          name="keywords"
          content="swifty, wallet, crypto, coin, token, blockchain, mobile, self-custody, NFT"
        />
        <meta
          property="og:keywords"
          content="swifty, wallet, crypto, coin, token, blockchain, mobile, self-custody, NFT"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.swifty-wallet.com" />
        <meta name="twitter:title" content="Swifty Wallet | Home" />
        <meta
          name="twitter:description"
          content="Swifty Wallet is a muti-chain wallet that puts you in control of your crypto, keys and data. Buy, Swap and Watch your favorite crypto coins and tokens. NFT's coming soon."
        />
        <meta name="twitter:image" content="/assets/images/meta-image.png" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Header />
      <div className="index-container">
        <div className="center-container">
          <Chart />
          <div className="index-content">
            <h3 className="index-finance">
              Control every aspect of your finances{" "}
            </h3>
            <label className="index-finance-lable">
              Download the Swifty Wallet App now
            </label>
            <br />
            <div className="index-app-buttons">
              <Button
                className="secondary btn-install-app btn-app-store"
                ariaLabel="app-store"
                onClick={redirectAppStoreLink}
              >
                <div>
                  <AppStoreLogo />
                </div>
              </Button>
              <Button
                className="secondary btn-install-app btn-play-store"
                ariaLabel="google-store"
                onClick={redirectPlayStoreLink}
              >
                <div>
                  <GoogleStoreLogo />
                </div>
              </Button>
            </div>
            <img
              className="index-phones"
              src="/assets/images/compress_home.png"
              alt="contact-us-phones"
            />
          </div>
          <div className="index-overview">
            <div className="overview-text-container">
              <span className="know-the-app">Get to know the app</span>
              <span className="overview-text">Overview</span>
            </div>
            <div className="overview-elements">
              <Overview
                icon={<IntroductionIcon />}
                title="Stay In Control"
                text="Swifty Wallet is a self-custody wallet, giving you full control of your crypto"
              />
              <Overview
                icon={<DescriptionIcon />}
                title="Security"
                text="Swifty Wallet provides you with security options to keep your crypto safe"
              />
              <Overview
                icon={<RequirementsIcon />}
                title="Multichain Wallet "
                text="Our decentralised wallet combines multiple wallets like ETH and SOL so you don't need multiple wallets"
              />
            </div>
          </div>
        </div>
        <Slider />
        <div className="center-container">
          {!isMobile ? (
            <div className="video-responsive">
              {" "}
              <iframe
                width="80"
                height="500"
                src="https://www.youtube.com/embed/pLjhAAfPiD0"
                title="YouTube video player"
                frameBorder="0"
                allowFullScreen
              ></iframe>{" "}
            </div>
          ) : (
            ""
          )}
          <div className="integrated-dex-aggregator">
            <div className="dex-aggregator-container">
              <span className="integrated-dex-aggregator-text">
                With our integrated Dex Aggregator
              </span>
              <span className="swap-text">
                Anyone can quickly swap Coins and Tokens
              </span>
            </div>
            <div className="swap-icons">
              <Link
                to={{ pathname: "https://www.uniswap.com" }}
                target="_blank"
                aria-label="unieswap"
              >
                <img src="/assets/images/unieswap.png" alt="unieswap" />
              </Link>
              <Link
                to={{ pathname: "https://pancakeswap.finance/" }}
                target="_blank"
                aria-label="pancakeswap"
              >
                <img src="/assets/images/pancakeswap.png" alt="pancakeswap" />
              </Link>
              <Link
                to={{ pathname: "https://www.sushi.com" }}
                target="_blank"
                aria-label="sushiswap"
              >
                <img src="/assets/images/sushiswap.png" alt="sushiswap" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Index;
