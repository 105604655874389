import React from "react";
import "./Crypto.css"
import PageHeader from "../../../components/pages-header/PageHeader";
import Footer from "../../../components/footer/Footer";
import { Helmet } from "react-helmet";

const Crypto = () =>{
    return(
        <React.Fragment>
            <Helmet>
                <title>Swifty Wallet | What is Crypto?</title>
                <meta name="title" content="Swifty Wallet | What is Crypto?"/>
                <meta name="description" content="A cryptocurrency, crypto-currency, or crypto is a digital currency designed to work as a medium of exchange through a computer network that is not reliant on any central authority, such as a government or bank, to uphold or maintain it."/>
                <meta name="keywords" content="Bitcoin, Ethereum, crypto, bank, money, decentralised, market, currency"/>
            </Helmet>
            <PageHeader
                firstPage={"What is Crypto?"}
                secondPage={"What is NFT?"}
                thirdPage={"How To Buy?"}    
                activeClass={"first"}
                clickable={true}
                firstLink={"/crypto"}
                secondLink={"/nft"}
                thirdLink={"/how_to_buy"}
            />
            <div className="academy-container">
                <div className="center-container">
                    <div className="row academy-description">
                        <div className="col-6">
                            <div className="academy-title">Bitcoin, Ethereum, and other crypto are revolutionizing how we invest, bank, and use money.</div>
                            <label className="academy-label">This guide will give you a basic understand of what cryptocurrency is.</label>
                        </div>
                        <img src="/assets/images/compress_crypto.png" alt="how-to-buy" className="academy-coins"/>
                    </div>
                    <div className="row academy-row">
                        <div className="col-12">
                            <div className="academy-bold-title">
                                Cryptocurrency in a snapshot
                            </div>
                            <label className="academy-bold-label">
                                A cryptocurrency, crypto-currency, or crypto is a digital currency designed to work as a medium of exchange through a computer network that is not reliant on any central authority, such as a government or bank, to uphold or maintain it.
                            </label>
                        </div>
                    </div>
                </div>
                {/* <div className="academy-video-container academy-row">
                    <img src="/assets/images/what_are_crypto.png" alt="crypto"/>
                </div> */}
                <div className="center-container">
                    <div className="row academy-row" style={{marginBottom: "70px"}}>
                        <div className="col-12">
                            <div className="academy-bold-title">
                                What is Cryptocurrency?
                            </div>
                            <label className="academy-bold-label">
                                At its core, cryptocurrency is typically decentralized digital money designed to be used over the internet. Bitcoin, which launched in 2008, was the first cryptocurrency, and it remains by far the biggest, most influential, and best-known. In the decade since, Bitcoin and other cryptocurrencies like Ethereum have grown as digital alternatives to money issued by governments.
                            </label>
                        </div>
                    </div>
                    <div className="row academy-row" style={{marginBottom: "70px"}}>
                        <img src="/assets/images/compress_crypto_currency.jpg" alt="crypto"/>
                    </div>
                    <div className="row academy-row">
                        <div className="col-12 col-lg-6 col-xl-6">
                            <ul>
                                <li>
                                    The most popular cryptocurrencies, by market capitalization, are Bitcoin (BTC), Ethereum (ETH), Binance Coin (BNB) and XRP. Other well-known cryptocurrencies include ADA, SOL, and DOT. Some are similar to Bitcoin. Others are based on different technologies, or have new features that allow them to do more than transfer value.
                                </li>
                                <li>
                                 Crypto makes it possible to transfer value online without the need for a middleman like a bank or payment processor, allowing value to transfer globally, near-instantly, 24/7, normally for low fees.
                                </li>
                                <li>
                                    Cryptocurrencies are usually not issued or controlled by any government or other central authority. They're managed by peer-to-peer networks of computers running free, open-source software. Generally, anyone who wants to participate is able to.
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-lg-6 col-xl-6">
                            <ul>
                                <li>
                                    If a bank or government isn't involved, how is crypto secure? All transactions are stored on a public ledger and once written can not be overridden.
                                </li>
                                <li>
                                    A cryptocurrency blockchain is similar to a bank's balance sheet or ledger. Each currency has its own blockchain, which is an ongoing, constantly re-verified record of every single transaction ever made using that currency.
                                </li>
                                <li>
                                    Unlike a bank's ledger, a crypto blockchain is distributed across participants of the digital currency’s entire network
                                </li>
                                <li>
                                    No company, country, or third party is in control of it; and anyone can participate. A blockchain is a breakthrough technology only recently made possible through decades of computer science and mathematical innovations.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row academy-row" style={{marginBottom: "70px"}}>
                        <div className="col-12">
                            <div className="academy-bold-title">
                                Why is Cryptocurrency the future of finance?
                            </div>
                            <label className="academy-bold-label">
                                The 2 trillion crypto economy is impossible to be overlooked. The evolution of new technologies, financial innovations and the dramatic evolution of digital currencies is transforming the way we use money.                            </label>
                        </div>
                    </div>
                    <div className="row academy-row">
                        <img src="/assets/images/compress_crypto_finance.jpg" alt="crypto"/>
                    </div>
                    <div className="row academy-row">
                        <div className="col-12 col-lg-6 col-xl-6">
                            <ul>
                                <li>
                                    Gone are the days of taking out cash from an ATM, applying for a mortgage by visiting a bank branch, or shopping in a department store. Now, for many, conducting financial transactions of any kind is a purely online experience and blockchain is at the forefront of this technological change.                                </li>
                                <li>
                                    Cryptocurrencies and faster, more powerful financial technologies are transforming our concept of money and challenging the financial institutions that currently manage it.                                </li>
                                <li>
                                    Cryptocurrency is already being used for speculation (trading) 24/7, a store of value (investment), a payment service, interest through the form of staking, lending and borrowing.                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-lg-6 col-xl-6">
                            <ul>
                                <li>
                                    Bitcoin was the first cryptocurrency and is generally compared to “virtual” gold. Bitcoin transaction speeds and high transaction fees makes BTC unlikely to ever be used as a mainstream payment coin.                                </li>
                                <li>
                                    Ethereum leads the market in different ways as it has many practical applications in the blockchain industry, such as building tokens on the Ethereum network, Non Fungible Tokens (NFT)'s.                                </li>
                                <li>
                                    In 2021, institutions such as Micro Startegy, Tesla and other institutions started to procure digital assets. Currently, about 300 million people, or 4% of the world's population, are using cryptocurrencies in some form, and some industry players hope and believe that could rise significantly                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row academy-row" style={{marginBottom: "70px"}}>
                        <div className="col-12">
                            <div className="academy-bold-title">
                                How does Cryptocurrency work?
                            </div>
                            <label className="academy-bold-label">
                                Cryptocurrency works a lot like PayPal or a debit card, except you exchange digital assets such as tokens for goods and services. Transfers work peer to peer using smart contracts, to send or recieve crypto you must first have a wallet like Swifty Wallet. All transactions are recorded on a public ledger and are not reliant on a middle man or entity to process the transaction.
                            </label>
                        </div>
                    </div>
                    <div className="row academy-row">
                        <img src="/assets/images/compress_blockchan.jpg" alt="crypto"/>
                    </div>
                    <div className="row academy-row">
                        <div className="col-12 col-lg-6 col-xl-6">
                            <ul>
                                <li>
                                    The goal of bloackchain is to allow digital information to be recorded and distributed, but not edited. This creates an immutable ledger that cannot be altered, deleted or destroyed.
                                </li>
                                <li>
                                    To send a transfer to another person you will need their public address which is usually a 42 alphanumeric phrase. You will enter the value you wish to send and their address.
                                </li>
                                
                            </ul>
                        </div>
                        <div className="col-12 col-lg-6 col-xl-6">
                            <ul>
                                <li>
                                    The transaction is then processed using a network of peer to peer computers or nodes which are scattered across the world. This network of computers then solve equations to confirm the validity of these transactions.                   
                                </li>
                                <li>
                                    Once the transaction is validated it is merged with other transactions to create a new block of data which is then joined to the blockchain.
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </React.Fragment>
    )
}

export default Crypto