import React, {useContext, useState, useEffect} from "react";
import "./Chart.css"
import { AppContext } from "../../context/AppContext";
import axios from "axios";

const Chart = () =>{
    const [marketData, setMarketData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [mobileBreakpoint, setMobileBreakpoint] = useState(
        window.document.documentElement.clientWidth < 768
    )
    const [marketCap, setMarketCap] = useState('')
    let market_cap = ''


    const getMarketTrendData = () =>{
        axios
        .get("https://05j9ju5id2.execute-api.eu-west-1.amazonaws.com/prod/v1/market/website_chart")
        .then(response => {
            if(response.status === 200){
                if(response.data.tokens){
                    setMarketCap(response.data.market_cap)
                    setMarketData(response.data.tokens)
                }else{
                    setMarketData(response.data)
                }
                setIsLoading(false)
            }
        })
        .catch(err =>{
            console.log(err)
        })
        
    }

    const resizeHandler = () => {
        setMobileBreakpoint(window.document.documentElement.clientWidth < 768);
    };

    useEffect(()=>{
        window.addEventListener("resize", resizeHandler);
        getMarketTrendData()
        return () => window.removeEventListener("resize", resizeHandler);
    }, [])

    let market = ''
    let marketText = ''
    if(marketCap){
        market = marketCap;

        market = market.replace("%", "")
        market = Number(market)
    }

    if(market < 0 ){
        marketText = `Market is down ${market}% in the last 24 hours` 
    }else{
        marketText = `Market is up ${market}% in the last 24 hours` 
    }
    return(
        <>
            {
                !isLoading ? 
                    <div className="market-trend-container">
                        <div className="row m-0 mb-5 align-items-center">
                            <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                                <span className="market-trend">Market Trend</span>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 col-xl-6 market_percent ">
                                {
                                    marketText
                                }
                                
                            </div>
                        </div>
                        {
                            !mobileBreakpoint ? 
                                marketData.length > 0   ?
                                    marketData.map((row, index)=>{
                                        let price = Number(row.price)
                                        let percent = Number(row.percent_change_24h)
                                        let percentClass = ''
                                        let percentSymbol = ''

                                        if(percent < 0){
                                            percentClass = 'danger'
                                            percentSymbol = ''
                                        }else{
                                            percentClass = 'success'
                                            percentSymbol = '+'
                                        }

                                        let totalPrice = (price / 100) * percent
                                        price = price.toLocaleString(undefined, {minimumFractionDigits: 2,  maximumFractionDigits: 2})
                                        totalPrice = totalPrice.toLocaleString(undefined, {minimumFractionDigits: 2,  maximumFractionDigits: 2})
                                        percent = percent.toLocaleString(undefined, {minimumFractionDigits: 2,  maximumFractionDigits: 2})

                                        return(
                                            <div className="row m-0 mb-4 align-items-center" key={index}>
                                                <div className="col-4 col-md-1 col-lg-1 col-xl-1 coin-image">
                                                    <img src={"https://swifty-wallet-v2.s3.amazonaws.com/" + row.logoURI} alt="etherum" className="market-logo"/>
                                                </div>
                                                <div className="col-4 col-md-2 col-lg-1 col-xl-1 coin-type d-flex align-items-center">{row.symbol}</div>
                                                <div className="col-4 col-md-2 col-lg-3 col-xl-3 coin-name d-flex align-items-center">{row.name}</div>
                                                <div className="col-6 col-md-3 col-lg-3 col-xl-3 coin-value text-align-right"> ${price} </div>
                                                <div className={"col-3 col-md-2 col-lg-2 col-xl-2 coin-percent text-align-right " + percentClass}>
                                                    {percentSymbol} {totalPrice} 
                                                </div>
                                                <div  className={"col-3 col-md-2 col-lg-2 col-xl-2 coin-percent text-align-right " + percentClass}>
                                                    {percentSymbol} {percent}%
                                                </div>
                                            </div>
                                        )
                                    })
                                : ""
                            : 
                            marketData.length > 0   ?
                                marketData.map((row, index)=>{
                                    let price = Number(row.price)
                                    let percent = Number(row.percent_change_24h)
                                    let percentClass = ''
                                    let percentSymbol = ''

                                    if(percent < 0){
                                        percentClass = 'danger'
                                        percentSymbol = ''
                                    }else{
                                        percentClass = 'success'
                                        percentSymbol = '+'
                                    }

                                    let totalPrice = (price / 100) * percent
                                    price = price.toLocaleString(undefined, {minimumFractionDigits: 2,  maximumFractionDigits: 2})
                                    totalPrice = totalPrice.toLocaleString(undefined, {minimumFractionDigits: 2,  maximumFractionDigits: 2})
                                    percent = percent.toLocaleString(undefined, {minimumFractionDigits: 2,  maximumFractionDigits: 2})

                                    return(
                                        <div className="row m-0 mb-4 align-items-center mobile-chart" key={index}>
                                            <div className="col-2 coin-image">
                                                <img src={"https://swifty-wallet-v2.s3.amazonaws.com/" + row.logoURI} alt="etherum" className="market-logo"/>
                                            </div>
                                            <div className="col-4">
                                                <span className="coin-type d-block">{row.symbol}</span>
                                                <span className="coin-name d-block">{row.name}</span>
                                            </div>
                                            <div className="col-6">
                                                <span className={"coin-value d-block text-align-right " + percentClass} >${price}</span>
                                                <span className={"coin-percent d-block text-align-right " + percentClass} >
                                                    <span className="mr-2">{percentSymbol} {totalPrice} </span>
                                                    <span>{percentSymbol} {percent}% </span>
                                                </span>
                                            </div>
                                        </div>
                                    )
                                })
                            : ""    
                        }
                    </div>
                : (
                    <div className="market-trend-container loading-skeleton">
                        <div className="row m-0 mb-5 align-items-center">
                            <div className="col-12 col-md-6 col-lg-6 col-xl-6 p-0">
                                <span style={{ maxWidth: 250 }} className="skeleton-box"></span>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                                <span style={{ maxWidth: 370 }} className="skeleton-box"></span>
                            </div>
                        </div>
                        <div className="row m-0 mb-4 align-items-center">
                            <div className="col-4 col-md-1 col-lg-1 col-xl-1 coin-image skeleton-box-coin">
                                <img className="market-logo"/>
                            </div>
                            <div className="col-4 col-md-2 col-lg-1 col-xl-1 coin-type d-flex align-items-center">
                                <div style={{ maxWidth: 60 }} className="skeleton-box"></div>
                            </div>
                            <div className="col-4 col-md-2 col-lg-3 col-xl-3 coin-name d-flex align-items-center">
                                <div style={{ maxWidth: 150 }} className="skeleton-box"></div>
                            </div>
                            <div className="col-6 col-md-3 col-lg-3 col-xl-3 text-align-right"> 
                                    <div style={{ maxWidth: 120 }} className="skeleton-box"></div>
                            </div>
                            <div className="col-3 col-md-2 col-lg-2 col-xl-2 coin-percent text-align-right">
                                <div style={{ maxWidth: 90 }} className="skeleton-box"></div>
                            </div>
                            <div  className="col-3 col-md-2 col-lg-2 col-xl-2 coin-percent text-align-right">
                                <div style={{ maxWidth: 90 }} className="skeleton-box"></div>
                            </div>
                        </div>
                        <div className="row m-0 mb-4 align-items-center">
                            <div className="col-4 col-md-1 col-lg-1 col-xl-1 coin-image skeleton-box-coin">
                                <img className="market-logo"/>
                            </div>
                            <div className="col-4 col-md-2 col-lg-1 col-xl-1 coin-type d-flex align-items-center">
                                <div style={{ maxWidth: 60 }} className="skeleton-box"></div>
                            </div>
                            <div className="col-4 col-md-2 col-lg-3 col-xl-3 coin-name d-flex align-items-center">
                                <div style={{ maxWidth: 150 }} className="skeleton-box"></div>
                            </div>
                            <div className="col-6 col-md-3 col-lg-3 col-xl-3 text-align-right"> 
                                    <div style={{ maxWidth: 120 }} className="skeleton-box"></div>
                            </div>
                            <div className="col-3 col-md-2 col-lg-2 col-xl-2 coin-percent text-align-right">
                                <div style={{ maxWidth: 90 }} className="skeleton-box"></div>
                            </div>
                            <div  className="col-3 col-md-2 col-lg-2 col-xl-2 coin-percent text-align-right">
                                <div style={{ maxWidth: 90 }} className="skeleton-box"></div>
                            </div>
                        </div>
                        <div className="row m-0 mb-4 align-items-center">
                            <div className="col-4 col-md-1 col-lg-1 col-xl-1 coin-image skeleton-box-coin">
                                <img className="market-logo"/>
                            </div>
                            <div className="col-4 col-md-2 col-lg-1 col-xl-1 coin-type d-flex align-items-center">
                                <div style={{ maxWidth: 60 }} className="skeleton-box"></div>
                            </div>
                            <div className="col-4 col-md-2 col-lg-3 col-xl-3 coin-name d-flex align-items-center">
                                <div style={{ maxWidth: 150 }} className="skeleton-box"></div>
                            </div>
                            <div className="col-6 col-md-3 col-lg-3 col-xl-3 text-align-right"> 
                                    <div style={{ maxWidth: 120 }} className="skeleton-box"></div>
                            </div>
                            <div className="col-3 col-md-2 col-lg-2 col-xl-2 coin-percent text-align-right">
                                <div style={{ maxWidth: 90 }} className="skeleton-box"></div>
                            </div>
                            <div  className="col-3 col-md-2 col-lg-2 col-xl-2 coin-percent text-align-right">
                                <div style={{ maxWidth: 90 }} className="skeleton-box"></div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default Chart