import React from "react";
import Index from "../pages/index/Index";
import { Redirect } from "react-router-dom";
import PrivacyPolicy from "../pages/privacy-policy/PrivacyPolicy";
import AboutUs from "../pages/about-us/AboutUs";
import Download from "../pages/download/download";
import Features from "../pages/features/Features";
import Crypto from "../pages/academy/crypto/Crypto";
import Nft from "../pages/academy/nft/Nft";
import HowToBuy from "../pages/academy/how-to-buy/HowToBuy";
import CookiePolicy from "../pages/cookies-policy/CookiesPolicy";
import TermsAndConditions from "../pages/terms-and-conditions/TermsAndConditions";
import Faq from "../pages/faq/Faq";
import ContactUs from "../pages/contact-us/ContactUs";


const userRoutes = [
    // Index
    { path: "/", component: Index },
    { path: "/home", component: Index },

    // Footer
    { path: "/about_us", component: AboutUs },
    { path: "/privacy_policy", component: PrivacyPolicy },
    { path: "/cookie_policy", component: CookiePolicy },
    { path: "/terms_and_conditions", component: TermsAndConditions },

    //Faq 
    { path: "/faq", component: Faq },

    // Contact Us
    { path: "/contact_us", component: ContactUs },

    
    // Download app
    { path: "/download", component: Download },

    // Features
    { path: "/features", component: Features },

    // Academy
    { path: "/crypto", component: Crypto },
    { path: "/nft", component: Nft },
    { path: "/how_to_buy", component: HowToBuy },


    { path: "/", exact: true, component: () => <Redirect  to="/" /> }, 

]

export {userRoutes}
