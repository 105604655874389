import React, {useState, useEffect} from 'react';
import './App.css';
import {AppContext} from "./context/AppContext";
import 'bootstrap/dist/css/bootstrap.min.css';
import {userRoutes} from "./routes/Routes"
import { Switch, BrowserRouter as Router } from "react-router-dom"
import { Route } from 'react-router-dom/cjs/react-router-dom.min';
import ScrollTop from './components/scroll-top/ScrollTop';

function App() {
  const [isMobile, setIsMobile] = useState(
    window.document.documentElement.clientWidth <= 991
  );

  let redirectLink = '/download'
  

  if(navigator.userAgent){
    let userAgent = navigator.userAgent.toLowerCase();
    if(userAgent.indexOf("android") > -1){
      redirectLink = 'https://play.google.com/store/apps/details?id=com.swiftywallet'
    }
    if(userAgent.indexOf("mac") > -1){
      redirectLink = 'https://apps.apple.com/ae/app/swifty-wallet/id1585778156'
    }
  }

  useEffect(()=>{
    window.addEventListener("resize", resizeHandler);
    return () => window.removeEventListener("resize", resizeHandler);
  })

  const resizeHandler = () => {
    setIsMobile(window.document.documentElement.clientWidth <= 991);
  };
  return (
    <AppContext.Provider
      value={{
        isMobile : isMobile,
        appleLink : 'https://apps.apple.com/ae/app/swifty-wallet/id1585778156',
        googleLink : 'https://play.google.com/store/apps/details?id=com.swiftywallet',
        redirectLink : redirectLink
      }}
    >
      <Router>
        <ScrollTop/>
        <Switch>
        {userRoutes.map((route, index) => (
						<Route
							path={route.path}
							component={route.component}
							key={index}
              exact
						/>
					))}
        </Switch>
      </Router>
    </AppContext.Provider>
  );
}

export default App;
