let faqData = [    
    {
        Q : "How can I buy Crypto using Swifty Wallet?",
        A : "Swifty has partnered with RAMP network so our users can purchase crypto directly within the Swifty Wallet application. "
    },
    {
        Q : "What is an ERC20 Token?",
        A : "This is a technical standard used for all smart contracts on the Ethereum blockchain. ERC20 tokens are blockchain based assets that have a value and can be sent and received. Other networks like AVAX, BNB, FTM and MATIC use the same standard."
    },
    {
        Q : "What is an NFT? ",
        A : "NFT is a non fungible token. NFT's are digital artwork, music, videos etc are unique digital assets in which the ownership rights are stored on a blockchain. Check out our <a href='/nft' target='_blank' class='faq-link'>academy page </a> for more info."
    },
    {
        Q : "Does Swifty have a coin or token? ",
        A : "Swifty does not currently have a coin or token. "
    },
    {
        Q : "How do I get a new network added? ",
        A : "You can request a new network to be added through the contact us page. ERC20 networks like AVAX, BNB, ETH, FTM and MATIC can be added after verification within 72 hours. Other networks like Bitcoin and Solana will be down to popularity and demand."
    },
    {
        Q : "Which networks does the swap functionality support?",
        A : "Currently we support swapping of tokens on the Ethereum and Binance Smart Chains."
    },
    {
        Q : "How do I get a new coin added to the swap list? ",
        A : "We automatically add main coins and tokens to the swap list but if you find that we are missing a token and wish to have it added please send us the network and the token to hello@swifty.global."
    },
    {
        Q : "Why does Swifty Wallet create a second wallet address when I import a wallet?",
        A : "If you import a 12 seed wallet, Swifty Wallet will automatically create a 24 seed wallet. This is for increased security used by other blockchains like Solana. Remember to backup this new wallet address if you are using the Solana wallet."
    },
    {
        Q : "What is Wallet Connect (QR) on the Swifty Wallet? ",
        A : "Swifty Wallet uses Wallet Connect to connect to any blockchain web app. For example if you go to <a href='https://uniswap.com' target='_blank' class='faq-link'> uniswap.com </a>  you can connect your wallet by selecting the Wallet Connect icon. "
    },

]

export default faqData